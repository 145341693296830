import { Box, Typography } from '@mui/material';
import { InternalLink } from 'components/shared/InternalLink';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSignInRoute } from 'routes';
import { useVerifyEmailMutation } from 'store/users/usersApi';
import { getErrorMessage } from 'utils';

export function VerifyEmailPage() {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId');
  const token = searchParams.get('token');

  const [verifyEmail, verifyEmailState] = useVerifyEmailMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId && token) {
      verifyEmail({ userId, token });
    }
  }, [verifyEmail, userId, token]);

  useEffect(() => {
    if (verifyEmailState.isSuccess && verifyEmailState.data) {
      navigate(getSignInRoute(verifyEmailState.data.email));
    }
  }, [navigate, verifyEmailState]);

  return (
    <Box py={2}>
      <Typography component="h3" variant="h6" gutterBottom>
        Verifying...
      </Typography>

      <QueryStateDisplay
        queryState={verifyEmailState}
        help={
          getErrorMessage(verifyEmailState.error) === 'Email already verified.' && (
            <InternalLink to={getSignInRoute()}>Sign in here</InternalLink>
          )
        }
      />
    </Box>
  );
}
