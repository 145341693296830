import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { ExternalLink } from 'components/shared/ExternalLink';
import { InternalLink } from 'components/shared/InternalLink';
import { PasswordField } from 'components/shared/PasswordField';
import { useAuthProvider } from 'providers/AuthProvider';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSignInRoute } from 'routes';
import { ISignUpPayload } from 'store/users/types';
import { useSignUpMutation } from 'store/users/usersApi';
import { getErrorMessage } from 'utils';
import { OrSignInAnonymouslyControl } from './OrSignInAnonymouslyControl';

export function SignUpPage() {
  const { user } = useAuthProvider();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const [signUp, signUpState] = useSignUpMutation();

  const [payload, setPayload] = useState<ISignUpPayload>({ email: '', username: '', password: '' });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState<boolean>();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (payload.password !== confirmPassword) {
      setPasswordsDoNotMatch(true);
      return;
    }

    signUp(payload);
  };

  useEffect(() => {
    if (passwordsDoNotMatch !== undefined) {
      setPasswordsDoNotMatch(payload.password !== confirmPassword);
    }
  }, [payload.password, confirmPassword, passwordsDoNotMatch, setPasswordsDoNotMatch]);

  if (signUpState.isSuccess) {
    return (
      <Alert severity="success" sx={{ my: 2 }}>
        <strong>Sign up successful!</strong> We have sent a verification email to{' '}
        <b>{signUpState.data.email}</b>. Please open the link in the email to finish your sign up.
      </Alert>
    );
  }

  const disableSignInAnonymously =
    Boolean(payload.email) && Boolean(payload.username) && Boolean(payload.password);

  return (
    <Box py={2}>
      <Typography component="h3" variant="h6" gutterBottom>
        Create a new account
      </Typography>
      <Typography mb={2}>
        Already got an account? <InternalLink to={getSignInRoute()}>Sign in here</InternalLink>
      </Typography>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box component="form" p={2} onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormControl fullWidth>
                    <TextField
                      label="Email address"
                      type="email"
                      value={payload.email}
                      onChange={(e) => setPayload({ ...payload, email: e.target.value })}
                      helperText="We will send you a link to verify your email address."
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <TextField
                      label="Username"
                      value={payload.username}
                      onChange={(e) => setPayload({ ...payload, username: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <PasswordField
                      label="Password"
                      value={payload.password}
                      onChange={(e) => setPayload({ ...payload, password: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <PasswordField
                      label="Confirm password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      passwordsDoNotMatch={passwordsDoNotMatch}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormHelperText>
                    By signing up, you are agreeing to our {}
                    <ExternalLink href="/terms-of-service.html">Terms of Service</ExternalLink>
                    {' and '}
                    <ExternalLink href="/privacy-policy.html">Privacy Policy</ExternalLink>.
                  </FormHelperText>
                </Grid>
                <Grid item>
                  <LoadingButton variant="contained" type="submit" loading={signUpState.isLoading}>
                    Sign up
                  </LoadingButton>
                </Grid>
                {signUpState.isError && signUpState.error && (
                  <Grid item>
                    <Alert severity="error">{getErrorMessage(signUpState.error)}</Alert>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <OrSignInAnonymouslyControl disabled={disableSignInAnonymously} />
    </Box>
  );
}
