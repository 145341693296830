import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import {
  Alert,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
} from '@mui/material';
import { BASE_URL } from 'config';
import { useState } from 'react';

interface IProps {
  route: string;
}

export function ShareButton(props: IProps) {
  const { route } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showCopied, setShowCopied] = useState(false);

  const handleCopy = () => {
    const fullUrl = BASE_URL + route;
    navigator.clipboard.writeText(fullUrl);
    setShowCopied(true);
    handleCloseMenu();
  };

  const handleCloseSnackbar = () => {
    setShowCopied(false);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Button
        id="share-button"
        variant="outlined"
        startIcon={<ShareIcon />}
        aria-controls={open ? 'share-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
      >
        Share
      </Button>
      <Menu
        id="share-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': 'share-button' }}
      >
        <MenuItem onClick={handleCopy}>
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy URL to clipboard</ListItemText>
        </MenuItem>
      </Menu>

      <Snackbar open={showCopied} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert severity="success">URL copied to clipboard</Alert>
      </Snackbar>
    </Box>
  );
}
