import { Card, CardActions, CardContent } from '@mui/material';
import { InternalLink } from 'components/shared/InternalLink';
import { PropsWithChildren } from 'react';
import { getSpreaderRoute } from 'routes';
import { SpreaderAvatar } from './SpreaderAvatar';

interface IProps {
  id: string;
  slug: string;
  name: string;
  imageUrl: string | null;
}

export function SpreaderCard(props: PropsWithChildren<IProps>) {
  const { id, slug, name, imageUrl, children } = props;

  return (
    <Card>
      <CardContent>
        <SpreaderAvatar name={name} imageUrl={imageUrl} />
        {children}
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <InternalLink to={getSpreaderRoute(id, slug)}>View profile</InternalLink>
      </CardActions>
    </Card>
  );
}
