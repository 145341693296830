import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Chip, Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useAuthProvider } from 'providers/AuthProvider';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClaimAccountRoute } from 'routes';
import { useResetUserSpecificData } from 'store/useResetUserSpecificData';
import { useUserInfoQuery } from 'store/users/usersApi';

export function AccountMenu() {
  const queryState = useUserInfoQuery();

  const navigate = useNavigate();
  const { clearIdToken } = useAuthProvider();
  const { resetUserSpecificData } = useResetUserSpecificData();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (queryState.data === undefined) {
    return null;
  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    clearIdToken();
    resetUserSpecificData();
  };

  const { username, isAnonymous } = queryState.data;
  const avatarInitial = username[0].toUpperCase();

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="Account menu">
        <Chip
          variant="outlined"
          avatar={<Avatar>{avatarInitial}</Avatar>}
          label={username}
          onClick={handleOpenMenu}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isAnonymous && (
          <MenuItem onClick={() => navigate(getClaimAccountRoute())}>
            <ListItemIcon>
              <PersonAddIcon fontSize="small" />
            </ListItemIcon>
            Claim account
          </MenuItem>
        )}
        {isAnonymous && <Divider />}
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
}
