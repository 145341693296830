import EmailIcon from '@mui/icons-material/EmailOutlined';
import XIcon from '@mui/icons-material/X';
import { Box, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ExternalLink } from 'components/shared/ExternalLink';

export function GetInTouchBox() {
  return (
    <Box display={{ xs: 'none', md: 'block' }}>
      <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.200' }}>
        <Typography variant="h6" gutterBottom>
          Got feedback?
        </Typography>
        <Stack direction="column" spacing={1}>
          <Typography>Get in touch:</Typography>
          <ExternalLink href="https://x.com/whogothoaxed">
            <Stack direction="row" spacing={1} alignItems="center">
              <XIcon />
              <span>@whogothoaxed</span>
            </Stack>
          </ExternalLink>
          <ExternalLink href="mailto:hello@whogothoaxed.com">
            <Stack direction="row" spacing={1} alignItems="center">
              <EmailIcon />
              <span>hello@whogothoaxed.com</span>
            </Stack>
          </ExternalLink>
        </Stack>
      </Paper>
    </Box>
  );
}
