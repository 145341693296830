import { Box, List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { InternalLink } from 'components/shared/InternalLink';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { getStoryRoute } from 'routes';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';
import { useGetAllStoriesQuery } from 'store/stories/storiesApi';

const NUMBER_TO_SHOW = 10;

export function MoreStoriesWidget() {
  const { storyId } = useStoryRouteParams();

  const queryState = useGetAllStoriesQuery();

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const moreStories = queryState.data.stories
    .filter((story) => story.approvedAt != null && story.id !== storyId)
    .sort(() => Math.random() - 0.5)
    .slice(0, NUMBER_TO_SHOW);

  if (moreStories.length === 0) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        More stories
      </Typography>
      <List sx={{ py: 0 }}>
        {moreStories.map(({ id, slug, summary }) => (
          <ListItem key={id}>
            <InternalLink to={getStoryRoute(id, slug)}>{summary}</InternalLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
