import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { Chip, CircularProgress, Stack } from '@mui/material';

interface IProps {
  upvotesCount: number;
  isActive?: boolean;
  onClick?: () => void;
  loading?: boolean;
}

export function UpvotesControl(props: IProps) {
  const { upvotesCount, isActive, onClick, loading } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Chip
        icon={<ArrowCircleUpIcon />}
        variant={isActive ? 'filled' : 'outlined'}
        label={`${upvotesCount} upvote${upvotesCount !== 1 ? 's' : ''}`}
        onClick={onClick}
      />
      {loading && <CircularProgress size="1rem" />}
    </Stack>
  );
}
