import { configureStore } from '@reduxjs/toolkit';
import { spreadersApi } from './spreaders/spreadersApi';
import { storiesApi } from './stories/storiesApi';
import { usersApi } from './users/usersApi';

export const store = configureStore({
  reducer: {
    [spreadersApi.reducerPath]: spreadersApi.reducer,
    [storiesApi.reducerPath]: storiesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      spreadersApi.middleware,
      storiesApi.middleware,
      usersApi.middleware,
    ]),
});
