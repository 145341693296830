import { LoadingButton } from '@mui/lab';
import { Alert, Box, FormControl, Grid, Paper, TextField } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FormEvent, useState } from 'react';
import { ICreateOrUpdateStoryPayload } from 'store/stories/types';
import { getErrorMessage } from 'utils';

interface IProps {
  story?: ICreateOrUpdateStoryPayload;
  onSubmit: (story: ICreateOrUpdateStoryPayload) => void;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export function StoryForm(props: IProps) {
  const { story: initialStory, onSubmit, isLoading, error } = props;

  const [story, setStory] = useState<ICreateOrUpdateStoryPayload>(
    initialStory ?? { summary: '', imageUrl: '', imageAttribution: '' }
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(story);
  };

  return (
    <Paper>
      <Box component="form" p={2} onSubmit={handleSubmit}>
        <Alert severity="info" sx={{ mb: 2 }}>
          If possible, a story should be framed as the "official" narrative, ideally as pushed by
          the state and/or corporate media entities.
        </Alert>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                label="Summary"
                multiline
                rows={4}
                value={story.summary}
                onChange={(e) => setStory({ ...story, summary: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                label="Image URL (optional)"
                value={story.imageUrl ?? ''}
                onChange={(e) => setStory({ ...story, imageUrl: e.target.value })}
                helperText="If you can suggest a background image, it will help us publish this story more quickly."
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                label="Image attribution (optional)"
                value={story.imageAttribution ?? ''}
                onChange={(e) => setStory({ ...story, imageAttribution: e.target.value })}
                helperText="This enables us to credit the owner of the background image."
              />
            </FormControl>
          </Grid>
          <Grid item>
            <LoadingButton variant="contained" type="submit" loading={isLoading}>
              Submit
            </LoadingButton>
          </Grid>
          {error && (
            <Grid item>
              <Alert severity="error">{getErrorMessage(error)}</Alert>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
}
