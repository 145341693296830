export const ACCOUNT_PATH = '/account';
export const SPREADERS_PATH = '/spreaders';
export const STORIES_PATH = '/stories';

export function getSignInRoute(verifiedEmail?: string): string {
  const verifiedQueryParams = verifiedEmail ? `?verified=true&email=${verifiedEmail}` : '';
  return `${ACCOUNT_PATH}/sign-in${verifiedQueryParams}`;
}

export function getSignUpRoute(): string {
  return `${ACCOUNT_PATH}/sign-up`;
}

export function getClaimAccountRoute(): string {
  return `${ACCOUNT_PATH}/claim`;
}

export function getSpreaderRoute(spreaderId: string, slug: string | undefined): string {
  return `${SPREADERS_PATH}/${spreaderId}/${slug}`;
}

export function getAddSpreaderRoute(): string {
  return `${SPREADERS_PATH}/add`;
}

export function getEditSpreaderRoute(spreaderId: string, slug: string): string {
  return `${SPREADERS_PATH}/${spreaderId}/${slug}/edit`;
}

export function getAddStoryRoute(): string {
  return `${STORIES_PATH}/add`;
}

export function getStoryRoute(storyId: string, slug: string | undefined): string {
  return `${STORIES_PATH}/${storyId}/${slug}`;
}

export function getStorySpreadersRoute(storyId: string, slug: string): string {
  return `${STORIES_PATH}/${storyId}/${slug}/spreaders`;
}

export function getEditStoryRoute(storyId: string, slug: string): string {
  return `${STORIES_PATH}/${storyId}/${slug}/edit`;
}

export function getAnalysisRoute(storyId: string, slug: string, analysisId: string): string {
  return `${STORIES_PATH}/${storyId}/${slug}/analyses/${analysisId}`;
}

export function getAddAnalysisRoute(storyId: string, slug: string): string {
  return `${STORIES_PATH}/${storyId}/${slug}/analyses/add`;
}

export function getEditAnalysisRoute(storyId: string, slug: string, analysisId: string): string {
  return `${STORIES_PATH}/${storyId}/${slug}/analyses/${analysisId}/edit`;
}
