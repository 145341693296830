import { Typography } from '@mui/material';
import { formatDate } from 'utils';

interface IProps {
  publishedAt: string | null;
  createdByUsername: string;
}

export function Byline(props: IProps) {
  const { publishedAt, createdByUsername } = props;

  return (
    <Typography sx={{ fontStyle: 'italic' }}>
      {formatDate(publishedAt)} by {createdByUsername}
    </Typography>
  );
}
