import { Box, Chip, Stack, Typography } from '@mui/material';
import { formatDate } from 'utils';
import { ExternalLink } from './ExternalLink';

interface IProps {
  role: string | null;
  quote: string | null;
  sourceUrl: string | null;
  date: string | null;
}

export function SpreaderEventBox(props: IProps) {
  const { role, quote, sourceUrl, date } = props;

  return (
    <Box>
      {role && (
        <Stack direction="row" spacing={1}>
          <Typography>Role:</Typography>
          <Chip label={role} color="primary" size="small" />
        </Stack>
      )}
      {quote && <blockquote>{quote}</blockquote>}
      {(date || sourceUrl) && (
        <Typography>
          {quote && '— '}
          {date && (
            <Typography component="span" sx={{ fontStyle: 'italic' }}>
              {formatDate(date)}
              {sourceUrl ? ', ' : ''}
            </Typography>
          )}
          {sourceUrl && <ExternalLink href={sourceUrl}>Source</ExternalLink>}
        </Typography>
      )}
    </Box>
  );
}
