import { Box, Grid, Typography } from '@mui/material';
import { InternalLinkButton } from 'components/shared/InternalLinkButton';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { SpreaderAvatar } from 'components/shared/SpreaderAvatar';
import { SpreaderEventBox } from 'components/shared/SpreaderEventBox';
import { StoryCard } from 'components/shared/StoryCard';
import { useAuthProvider } from 'providers/AuthProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getEditSpreaderRoute, getSpreaderRoute } from 'routes';
import { useSpreaderRouteParams } from 'routes/useSpreaderRouteParams';
import { useGetSpreaderQuery } from 'store/spreaders/spreadersApi';

export function SpreaderPage() {
  const { spreaderId, slug: slugFromRoute } = useSpreaderRouteParams();

  const { user } = useAuthProvider();

  const queryState = useGetSpreaderQuery({ spreaderId });
  const navigate = useNavigate();

  useEffect(() => {
    if (queryState.data && queryState.data.slug !== slugFromRoute) {
      navigate(getSpreaderRoute(queryState.data.id, queryState.data.slug));
    }
  }, [navigate, queryState.data, slugFromRoute]);

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const spreader = queryState.data;

  return (
    <Box py={2}>
      <SpreaderAvatar name={spreader.name} imageUrl={spreader.imageUrl} size={100} />

      {spreader.bio && <div dangerouslySetInnerHTML={{ __html: spreader.bio }} />}

      {user?.isAdmin && (
        <Box mb={2}>
          <InternalLinkButton
            to={getEditSpreaderRoute(spreaderId, slugFromRoute)}
            size="small"
            variant="contained"
          >
            Edit
          </InternalLinkButton>
        </Box>
      )}

      {spreader.stories.length > 0 && (
        <>
          <Typography component="h3" variant="h6" gutterBottom>
            Stories spread by {spreader.name}
          </Typography>

          <Grid container spacing={2}>
            {spreader.stories.map(
              ({ id, storyId, storySlug, summary, imageUrl, role, quote, sourceUrl, date }) => (
                <Grid item key={id} xs={12} sm={4}>
                  <StoryCard
                    id={storyId}
                    slug={storySlug}
                    isApproved
                    summary={summary}
                    imageUrl={imageUrl}
                  >
                    <Box pt={1}>
                      <SpreaderEventBox
                        role={role}
                        quote={quote}
                        sourceUrl={sourceUrl}
                        date={date}
                      />
                    </Box>
                  </StoryCard>
                </Grid>
              )
            )}
          </Grid>
        </>
      )}
    </Box>
  );
}
