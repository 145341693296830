import { Button } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  to: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
}

export function InternalLinkButton(props: PropsWithChildren<IProps>) {
  const { to, size, variant, children } = props;

  return (
    <Button variant={variant} component={RouterLink} to={to} size={size}>
      {children ?? to}
    </Button>
  );
}
