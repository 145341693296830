import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'config';
import { setDefaultHeaders } from 'providers/AuthProvider';
import {
  ISignInPayload,
  ISignInResult,
  ISignUpPayload,
  ISignUpResult,
  IUserInfoResult,
  IVerifyEmailPayload,
  IVerifyEmailResult,
} from './types';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/users`,
    prepareHeaders: setDefaultHeaders,
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    signIn: builder.mutation<ISignInResult, ISignInPayload>({
      query: (payload) => ({ url: 'sign-in', method: 'POST', body: payload }),
    }),
    signInAnonymously: builder.mutation<ISignInResult, void>({
      query: () => ({ url: 'sign-in-anonymously', method: 'POST' }),
    }),
    signUp: builder.mutation<ISignUpResult, ISignUpPayload>({
      query: (payload) => ({ url: 'sign-up', method: 'POST', body: payload }),
    }),
    userInfo: builder.query<IUserInfoResult, void>({
      query: () => ({ url: 'info', method: 'GET' }),
      providesTags: () => [{ type: 'User', id: 'ME' }],
    }),
    verifyEmail: builder.mutation<IVerifyEmailResult, IVerifyEmailPayload>({
      query: (payload) => ({ url: 'verify-email', method: 'POST', body: payload }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignInAnonymouslyMutation,
  useSignUpMutation,
  useUserInfoQuery,
  useVerifyEmailMutation,
} = usersApi;
