import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Alert, Card, CardActions, CardContent, Chip, Stack, Typography } from '@mui/material';
import { InternalLink } from 'components/shared/InternalLink';
import { getAnalysisRoute } from 'routes';
import { IAnalysisDetail } from 'store/stories/types';
import { Byline } from './Byline';
import { UpvotesControl } from './UpvotesControl';
import { VerdictDisplay } from './VerdictDisplay';

interface IProps {
  storyId: string;
  slug: string;
  analysis: IAnalysisDetail;
  isTopRated?: boolean;
}

export function AnalysisCard(props: IProps) {
  const { storyId, slug, analysis, isTopRated } = props;
  const { id: analysisId, createdByUsername, verdict, upvotesCount, title, publishedAt } = analysis;

  const isPublished = publishedAt !== null;

  return (
    <Card>
      {!isPublished && <Alert severity="warning">Not yet published</Alert>}
      <CardContent>
        <Stack
          direction={{ xs: 'row', sm: 'column', md: 'row' }}
          alignItems={{ xs: 'center', sm: 'start', md: 'center' }}
          spacing={1}
          sx={{ mb: 1 }}
        >
          <VerdictDisplay value={verdict} isActive />
          <UpvotesControl upvotesCount={upvotesCount} />
          {isTopRated && <Chip icon={<StarBorderIcon />} label="Top rated" />}
        </Stack>

        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>

        <Byline publishedAt={publishedAt} createdByUsername={createdByUsername} />
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <InternalLink to={getAnalysisRoute(storyId, slug, analysisId)}>Read more</InternalLink>
      </CardActions>
    </Card>
  );
}
