import { Link } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  to: string;
}

export function InternalLink(props: PropsWithChildren<IProps>) {
  const { to, children } = props;

  return (
    <Link component={RouterLink} to={to}>
      {children ?? to}
    </Link>
  );
}
