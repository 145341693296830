import { CircularProgress } from '@mui/material';
import { lazy, Suspense } from 'react';

const HtmlEditorLazy = lazy(() => import(/* webpackChunkName: "HtmlEditor" */ './HtmlEditorLazy'));

interface IProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

export function HtmlEditor(props: IProps) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <HtmlEditorLazy {...props} />
    </Suspense>
  );
}
