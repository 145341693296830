import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function useCorrectSlug(
  slugFromRoute: string,
  data: { id: string; slug: string } | undefined,
  getRoute: (id: string, slug: string) => string
) {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.slug !== slugFromRoute) {
      navigate({
        pathname: getRoute(data.id, data.slug),
        search: searchParams.toString(),
      });
    }
  }, [navigate, data, slugFromRoute, getRoute, searchParams]);
}
