import { Box, Grid, Typography } from '@mui/material';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useGetAllSpreadersQuery } from 'store/spreaders/spreadersApi';
import { SpreaderCard } from '../shared/SpreaderCard';

export function SpreadersPage() {
  const queryState = useGetAllSpreadersQuery();

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const { spreaders } = queryState.data;

  return (
    <Box py={2}>
      <Grid container spacing={2}>
        {spreaders
          .slice()
          .sort((a, b) => b.storiesCount - a.storiesCount)
          .map(({ id, slug, name, imageUrl, storiesCount }) => (
            <Grid item key={id} xs={12} sm={4}>
              <SpreaderCard id={id} slug={slug} name={name} imageUrl={imageUrl}>
                <Typography>
                  has spread {storiesCount} {storiesCount === 1 ? 'story' : 'stories'}
                </Typography>
              </SpreaderCard>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
