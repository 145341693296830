export const BASE_URL = window.location.origin;

export const API_URL = BASE_URL.startsWith('http://localhost:')
  ? 'http://localhost:7019'
  : 'https://api.whogothoaxed.com';

export const IMAGE_MISSING_URL = '/images/image-missing.png';

export const brandColor = {
  main: '#fe987f',
  light: '#fef4f2',
};
