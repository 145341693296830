import { Link } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IProps {
  href: string;
}

export function ExternalLink(props: PropsWithChildren<IProps>) {
  const { href, children } = props;

  return (
    <Link href={href} target="_blank" rel="noreferrer" style={{ wordBreak: 'break-word' }}>
      {children ?? href}
    </Link>
  );
}
