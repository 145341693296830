import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { storiesApi } from './stories/storiesApi';
import { usersApi } from './users/usersApi';

/**
 * Provides a function to clear the store of user-specific information
 */
export function useResetUserSpecificData(): { resetUserSpecificData: () => void } {
  const dispatch = useDispatch();

  const resetUserSpecificData = useCallback(() => {
    dispatch(storiesApi.util.invalidateTags(['Story']));
    dispatch(usersApi.util.invalidateTags([{ type: 'User', id: 'ME' }]));
  }, [dispatch]);

  return { resetUserSpecificData };
}
