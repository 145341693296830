import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { SignInDialog } from 'components/Account/SignInDialog';
import { useEffect, useState } from 'react';

import { SPREADER_PAGE_STATE } from 'components/Spreaders/AddSpreaderPage';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAddSpreaderRoute } from 'routes';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';
import { spreadersApi } from 'store/spreaders/spreadersApi';
import { useCreateSpreaderEventMutation } from 'store/stories/storiesApi';
import { ICreateOrUpdateSpreaderEventPayload } from 'store/stories/types';
import { SpreaderEventForm } from './SpreaderEventForm';

export type ADD_SPREADER_EVENT_WIDGET_STATE = { spreaderId: string };

export function AddSpreaderEventWidget() {
  const { storyId } = useStoryRouteParams();

  const dispatch = useDispatch();

  const { pathname, state } = useLocation();
  const { spreaderId } = (state as ADD_SPREADER_EVENT_WIDGET_STATE) ?? {};

  const navigate = useNavigate();

  const [open, setOpen] = useState(Boolean(spreaderId));

  const [createSpreaderEvent, createSpreaderEventState] = useCreateSpreaderEventMutation();

  useEffect(() => {
    if (createSpreaderEventState.isSuccess) {
      dispatch(
        spreadersApi.util.invalidateTags([
          // List contains storiesCount
          { type: 'Spreader', id: 'LIST' },
          { type: 'Spreader', id: createSpreaderEventState.originalArgs?.payload.spreaderId },
        ])
      );

      setOpen(false);
    }
  }, [createSpreaderEventState, dispatch, setOpen]);

  const handleAddSpreader = (name?: string) => {
    navigate(getAddSpreaderRoute(), {
      state: { returnPath: pathname, name } as SPREADER_PAGE_STATE,
    });
  };

  const handleSubmit = (payload: ICreateOrUpdateSpreaderEventPayload) => {
    createSpreaderEvent({ storyId, payload });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>
        Add spreader
      </Button>
      <Dialog open={open} fullWidth onClose={handleClose}>
        <SignInDialog />
        <DialogTitle>Add spreader</DialogTitle>
        <DialogContent>
          <SpreaderEventForm
            spreaderEvent={{ spreaderId: spreaderId ?? undefined }}
            onAddSpreader={handleAddSpreader}
            onSubmit={handleSubmit}
            isLoading={createSpreaderEventState.isLoading}
            error={createSpreaderEventState.error}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
