import { Box, Button, Typography } from '@mui/material';
import { useAuthProvider } from 'providers/AuthProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetUserSpecificData } from 'store/useResetUserSpecificData';
import { useSignInAnonymouslyMutation } from 'store/users/usersApi';

interface IProps {
  disabled?: boolean;
}

export function OrSignInAnonymouslyControl(props: IProps) {
  const { disabled } = props;

  const [signInAnonymously, signInAnonymouslyState] = useSignInAnonymouslyMutation();

  const { setIdToken } = useAuthProvider();
  const { resetUserSpecificData } = useResetUserSpecificData();
  const navigate = useNavigate();

  useEffect(() => {
    if (signInAnonymouslyState.isSuccess) {
      setIdToken(signInAnonymouslyState.data.token);
      resetUserSpecificData();
    }
  }, [setIdToken, signInAnonymouslyState, resetUserSpecificData, navigate]);

  const handleClick = () => {
    signInAnonymously();
  };

  return (
    <Box mt={2}>
      <Typography component="h3" variant="h6" gutterBottom>
        Or sign in anonymously
      </Typography>
      <Typography mb={2}>
        You can always add an email address, username and password later if you choose to keep your
        account.
      </Typography>
      <Button variant="contained" onClick={handleClick} disabled={disabled}>
        Sign in anonymously
      </Button>
    </Box>
  );
}
