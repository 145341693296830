import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useAuthProvider } from 'providers/AuthProvider';
import { useState } from 'react';
import { useAnalysisRouteParams } from 'routes/useAnalysisRouteParams';
import { useGetStoryQuery, usePublishAnalysisMutation } from 'store/stories/storiesApi';
import { getErrorMessage } from 'utils';

export function PublishAnalysisWidget() {
  const { storyId, analysisId } = useAnalysisRouteParams();
  const { user } = useAuthProvider();

  const queryState = useGetStoryQuery({ storyId }, { skip: !user });

  const [publishAnalysis, publishAnalysisState] = usePublishAnalysisMutation();

  const [open, setOpen] = useState(false);

  if (queryState.data === undefined) {
    return null;
  }

  const analysis = queryState.data.analyses.find((analysis) => analysis.id === analysisId);

  const canPublish = user && user.id === analysis?.createdByUserId;

  if (!canPublish) {
    return null;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePublish = () => {
    publishAnalysis({ storyId, analysisId });
  };

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>
        Publish
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Publish this analysis?</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText tabIndex={-1}>{analysis.title}</DialogContentText>
            <Alert severity="warning">Once published, you will no longer be able to edit it.</Alert>
            {publishAnalysisState.error && (
              <Alert severity="error">{getErrorMessage(publishAnalysisState.error)}</Alert>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={handlePublish}
            loading={publishAnalysisState.isLoading}
          >
            Publish
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
