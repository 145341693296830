import { Alert, CircularProgress } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ReactNode } from 'react';
import { getErrorMessage } from 'utils';

type QueryState<T> = {
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
  data?: T;
};

interface IProps<T> {
  queryState: QueryState<T>;
  help?: ReactNode;
}

export function QueryStateDisplay<T>(props: IProps<T>) {
  const { queryState, help } = props;
  const { isLoading, error } = queryState;

  if (isLoading) {
    return <CircularProgress sx={{ my: 2 }} />;
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ my: 2 }}>
        {getErrorMessage(error) ?? 'An error occurred.'} {help}
      </Alert>
    );
  }

  return (
    <Alert severity="error" sx={{ my: 2 }}>
      No data.
    </Alert>
  );
}
