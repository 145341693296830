import { Alert, Grid, Stack } from '@mui/material';
import { GetInTouchBox } from 'components/shared/GetInTouchBox';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useAuthProvider } from 'providers/AuthProvider';
import { useAnalysisRouteParams } from 'routes/useAnalysisRouteParams';
import { useGetStoryQuery } from 'store/stories/storiesApi';
import { AnalysisDisplay } from './AnalysisDisplay';
import { HeaderWidget } from './HeaderWidget';
import { MoreAnalysesWidget } from './MoreAnalysesWidget';
import { MoreStoriesWidget } from './MoreStoriesWidget';

export function AnalysisPage() {
  const { storyId, slug, analysisId } = useAnalysisRouteParams();

  const { user } = useAuthProvider();

  const queryState = useGetStoryQuery({ storyId });

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const analysis = queryState.data.analyses.find((analysis) => analysis.id === analysisId);

  return (
    <article>
      <HeaderWidget />

      <Grid container spacing={5} sx={{ mt: 1 }}>
        <Grid item xs={12} md={8}>
          {analysis ? (
            <AnalysisDisplay
              storyId={storyId}
              slug={slug}
              analysis={analysis}
              currentUserId={user?.id ?? null}
            />
          ) : (
            <Alert severity="error">Analysis not found.</Alert>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack spacing={2}>
            <MoreAnalysesWidget showAddButton />
            <MoreStoriesWidget />
            <GetInTouchBox />
          </Stack>
        </Grid>
      </Grid>
    </article>
  );
}
