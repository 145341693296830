import { Alert, Box, Grid, Paper, Typography } from '@mui/material';
import { InternalLinkButton } from 'components/shared/InternalLinkButton';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { IMAGE_MISSING_URL } from 'config';
import { getEditStoryRoute } from 'routes';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';
import { useGetStoryQuery } from 'store/stories/storiesApi';
import { ApproveStoryWidget } from './ApproveStoryWidget';

export function HeaderWidget() {
  const { storyId, slug } = useStoryRouteParams();

  const queryState = useGetStoryQuery({ storyId });

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const { summary, imageAttribution, isApproved: isPublished } = queryState.data;
  const imageUrl = queryState.data.imageUrl ?? IMAGE_MISSING_URL;

  return (
    <Box mb={4}>
      {!isPublished && (
        <Alert severity="warning" action={<ApproveStoryWidget />} sx={{ my: 2 }}>
          This story is not yet published, so only you can see it. You will receive an email when it
          gets published.
        </Alert>
      )}

      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${imageUrl})`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        <img style={{ display: 'none' }} src={imageUrl} alt="" />

        {/* 30% opacity to dull the background image */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />

        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
              }}
            >
              <Typography component="h6">The story:</Typography>
              <Typography component="h1" variant="h4" gutterBottom>
                "{summary}"
              </Typography>
              {!isPublished && (
                <InternalLinkButton
                  to={getEditStoryRoute(storyId, slug)}
                  size="small"
                  variant="contained"
                >
                  Edit
                </InternalLinkButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {imageAttribution && (
        <Typography
          mt="1px"
          fontSize="x-small"
          textAlign="right"
          dangerouslySetInnerHTML={{ __html: imageAttribution }}
        ></Typography>
      )}
    </Box>
  );
}
