import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { InternalLink } from 'components/shared/InternalLink';
import { InternalLinkButton } from 'components/shared/InternalLinkButton';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { brandColor } from 'config';
import { getAddAnalysisRoute, getStoryRoute, getStorySpreadersRoute } from 'routes';
import { useCorrectSlug } from 'routes/useCorrectSlug';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';
import { useGetStoryQuery } from 'store/stories/storiesApi';
import { getInitials, sortAnalyses, sortByDate, sortByHasImageUrl } from 'utils';
import { AddSpreaderEventWidget } from './AddSpreaderEventWidget';
import { AnalysisCard } from './AnalysisCard';
import { HeaderWidget } from './HeaderWidget';

export function StoryPage() {
  const { storyId, slug: slugFromRoute } = useStoryRouteParams();

  const queryState = useGetStoryQuery({ storyId });

  useCorrectSlug(slugFromRoute, queryState.data, getStoryRoute);

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const story = queryState.data;

  const sortedAnalyses = sortAnalyses(story.analyses);

  return (
    <>
      <HeaderWidget />

      <Stack direction="row" spacing={1} alignItems="baseline" mb={1}>
        <Typography component="h3" variant="h6">
          Who spread this story?
        </Typography>
        <InternalLink to={getStorySpreadersRoute(storyId, story.slug)}>See all</InternalLink>
      </Stack>

      <Stack
        direction={{ xs: 'column', sm: 'row', md: 'row' }}
        alignItems={{ xs: 'center', sm: 'start', md: 'center' }}
        spacing={1}
        sx={{ mb: 1 }}
      >
        {story.spreaders.length > 0 ? (
          <AvatarGroup max={10} total={story.spreaders.length}>
            {story.spreaders
              .slice()
              .sort(sortByHasImageUrl)
              .sort(sortByDate)
              .map(({ id, name, imageUrl }) => (
                <Avatar key={id} alt={name} src={imageUrl ?? ''}>
                  {getInitials(name)}
                </Avatar>
              ))}
          </AvatarGroup>
        ) : (
          <AddSpreaderEventWidget />
        )}
      </Stack>

      <Typography component="h3" variant="h6" gutterBottom>
        Analyses
      </Typography>

      <Grid container spacing={2}>
        {sortedAnalyses.map(({ analysis, isTopRated }) => (
          <Grid item key={analysis.id} xs={12} sm={4}>
            <AnalysisCard
              storyId={story.id}
              slug={story.slug}
              analysis={analysis}
              isTopRated={isTopRated}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={4}>
          <Card elevation={4}>
            <Box sx={{ height: 140, backgroundColor: brandColor.light }}>
              <AddCircleOutlineIcon
                htmlColor={brandColor.main}
                sx={{ width: '100%', height: '100%' }}
              />
            </Box>
            <CardContent>
              <Typography fontWeight="bold">
                {story.analyses.length === 0 ? `What's your take?` : 'Got a better take?'}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <InternalLinkButton to={getAddAnalysisRoute(storyId, story.slug)} variant="outlined">
                Add your analysis
              </InternalLinkButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
