import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { InternalLinkButton } from 'components/shared/InternalLinkButton';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { StoryCard } from 'components/shared/StoryCard';
import { brandColor } from 'config';
import { getAddStoryRoute } from 'routes';
import { useGetAllStoriesQuery } from 'store/stories/storiesApi';

export function StoriesPage() {
  const queryState = useGetAllStoriesQuery();

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const { stories } = queryState.data;

  return (
    <Box py={2}>
      <Grid container spacing={2}>
        {stories.map(({ id, slug, summary, imageUrl, approvedAt }) => (
          <Grid item key={id} xs={12} sm={4}>
            <StoryCard
              id={id}
              slug={slug}
              isApproved={approvedAt != null}
              summary={summary}
              imageUrl={imageUrl}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={4}>
          <Card elevation={4}>
            <Box sx={{ height: 140, backgroundColor: brandColor.light }}>
              <AddCircleOutlineIcon
                htmlColor={brandColor.main}
                sx={{ width: '100%', height: '100%' }}
              />
            </Box>
            <CardContent>
              <Typography fontWeight="bold">Got a story to submit?</Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <InternalLinkButton to={getAddStoryRoute()} variant="outlined">
                Submit a new story
              </InternalLinkButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
