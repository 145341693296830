import { Avatar, Box } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { InternalLink } from 'components/shared/InternalLink';
import { useAuthProvider } from 'providers/AuthProvider';
import { getSignUpRoute } from 'routes';
import { AccountMenu } from './AccountMenu';

export function Title() {
  const { user } = useAuthProvider();

  const subheading = (
    <Typography color="text.secondary" fontSize="small" textAlign="center">
      An analysis of the most hotly debated stories of our time
    </Typography>
  );

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Toolbar>
        <InternalLink to="/">
          <Avatar alt="Logo" src="/logo1.png" />
        </InternalLink>
        <Stack direction="column" margin="auto">
          <Stack direction="row" alignItems="center" alignSelf="center" spacing={2}>
            <Typography component="h2" variant="h5" color="inherit" noWrap>
              Who Got Hoaxed?
            </Typography>
          </Stack>
          <Box display={{ xs: 'none', sm: 'block' }}>{subheading}</Box>
        </Stack>
        {user ? <AccountMenu /> : <InternalLink to={getSignUpRoute()}>Sign up</InternalLink>}
      </Toolbar>
      <Box display={{ xs: 'block', sm: 'none' }}>{subheading}</Box>
    </Box>
  );
}
