import { Alert, Grid, Typography } from '@mui/material';
import { SignInDialog } from 'components/Account/SignInDialog';
import { GetInTouchBox } from 'components/shared/GetInTouchBox';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getAnalysisRoute } from 'routes';
import { useAnalysisRouteParams } from 'routes/useAnalysisRouteParams';
import { useGetStoryQuery, useUpdateAnalysisMutation } from 'store/stories/storiesApi';
import { ICreateOrUpdateAnalysisPayload } from 'store/stories/types';
import { AnalysisForm } from './AnalysisForm';
import { HeaderWidget } from './HeaderWidget';

export function EditAnalysisPage() {
  const { storyId, slug, analysisId } = useAnalysisRouteParams();
  const queryState = useGetStoryQuery({ storyId });
  const [updateAnalysis, updateAnalysisState] = useUpdateAnalysisMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (updateAnalysisState.isSuccess) {
      navigate(getAnalysisRoute(storyId, slug, analysisId!));
    }
  }, [updateAnalysisState, navigate, storyId, slug, analysisId]);

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const handleSubmit = (analysis: ICreateOrUpdateAnalysisPayload) => {
    updateAnalysis({ storyId, analysisId, payload: analysis });
  };

  const analysis = queryState.data.analyses.find((analysis) => analysis.id === analysisId);

  return (
    <>
      <SignInDialog />

      <HeaderWidget />

      <Grid container spacing={5} sx={{ mt: 1 }}>
        <Grid item xs={12} md={8}>
          <Typography component="h3" variant="h6" gutterBottom>
            Edit your analysis
          </Typography>

          {analysis ? (
            <AnalysisForm
              analysis={analysis}
              onSubmit={handleSubmit}
              isLoading={updateAnalysisState.isLoading}
              error={updateAnalysisState.error}
            />
          ) : (
            <Alert severity="error">Analysis not found.</Alert>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <GetInTouchBox />
        </Grid>
      </Grid>
    </>
  );
}
