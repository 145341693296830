import { Outlet, Route } from 'react-router-dom';
import { ACCOUNT_PATH, SPREADERS_PATH, STORIES_PATH } from 'routes';
import { ClaimAccountPage } from './Account/ClaimAccountPage';
import { SignInPage } from './Account/SignInPage';
import { SignUpPage } from './Account/SignUpPage';
import { VerifyEmailPage } from './Account/VerifyEmailPage';
import { HomePage } from './HomePage/HomePage';
import { AppLayout } from './Layout/AppLayout';
import { AddSpreaderPage } from './Spreaders/AddSpreaderPage';
import { EditSpreaderPage } from './Spreaders/EditSpreaderPage';
import { SpreaderPage } from './Spreaders/SpreaderPage';
import { SpreadersPage } from './Spreaders/SpreadersPage';
import { AddAnalysisPage } from './Stories/AddAnalysisPage';
import { AddStoryPage } from './Stories/AddStoryPage';
import { AnalysisPage } from './Stories/AnalysisPage';
import { EditAnalysisPage } from './Stories/EditAnalysisPage';
import { EditStoryPage } from './Stories/EditStoryPage';
import { StoriesPage } from './Stories/StoriesPage';
import { StoryPage } from './Stories/StoryPage';
import { StorySpreadersPage } from './Stories/StorySpreadersPage';

export function AppRoutes() {
  return (
    <Route
      path="/"
      element={
        <AppLayout>
          <Outlet />
        </AppLayout>
      }
    >
      <Route path="" element={<HomePage />} />
      <Route path={ACCOUNT_PATH}>
        <Route path="claim" element={<ClaimAccountPage />} />
        <Route path="sign-in" element={<SignInPage />} />
        <Route path="sign-up" element={<SignUpPage />} />
        <Route path="verify-email" element={<VerifyEmailPage />} />
      </Route>
      <Route path={SPREADERS_PATH}>
        <Route path="" element={<SpreadersPage />} />
        <Route path="add" element={<AddSpreaderPage />} />
        <Route path=":spreaderId/:slug?">
          <Route path="" element={<SpreaderPage />} />
          <Route path="edit" element={<EditSpreaderPage />} />
        </Route>
      </Route>
      <Route path={STORIES_PATH}>
        <Route path="" element={<StoriesPage />} />
        <Route path="add" element={<AddStoryPage />} />
        <Route path=":storyId/:slug?">
          <Route path="" element={<StoryPage />} />
          <Route path="edit" element={<EditStoryPage />} />
          <Route path="spreaders" element={<StorySpreadersPage />} />
          <Route path="analyses">
            <Route path="add" element={<AddAnalysisPage />} />
            <Route path=":analysisId" element={<AnalysisPage />} />
            <Route path=":analysisId/edit" element={<EditAnalysisPage />} />
          </Route>
        </Route>
      </Route>
    </Route>
  );
}
