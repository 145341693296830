import { Alert, Box, FormControl, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormEvent, useState } from 'react';
import { useGetAllSpreadersQuery } from 'store/spreaders/spreadersApi';

import { LoadingButton } from '@mui/lab';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Autosuggest } from 'components/shared/Autosuggest';
import dayjs from 'dayjs';
import { ICreateOrUpdateSpreaderEventPayload } from 'store/stories/types';
import { DAYJS_DATE_FORMAT, getErrorMessage } from 'utils';

interface IProps {
  spreaderEvent?: Partial<ICreateOrUpdateSpreaderEventPayload>;
  onAddSpreader: (name?: string) => void;
  onSubmit: (spreaderEvent: ICreateOrUpdateSpreaderEventPayload) => void;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export function SpreaderEventForm(props: IProps) {
  const { spreaderEvent: initialSpreaderEvent, onAddSpreader, onSubmit, isLoading, error } = props;

  const queryState = useGetAllSpreadersQuery();

  const [spreaderEvent, setSpreaderEvent] = useState<ICreateOrUpdateSpreaderEventPayload>({
    spreaderId: initialSpreaderEvent?.spreaderId ?? '',
    role: initialSpreaderEvent?.role ?? '',
    quote: initialSpreaderEvent?.quote ?? '',
    sourceUrl: initialSpreaderEvent?.sourceUrl ?? '',
    date: initialSpreaderEvent?.date ?? '',
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(spreaderEvent);
  };

  return (
    <Box component="form" p={2} onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormControl fullWidth>
            <Autosuggest
              label="Name"
              value={spreaderEvent.spreaderId}
              options={queryState.data?.spreaders}
              onChange={(spreaderId) => setSpreaderEvent((prev) => ({ ...prev, spreaderId }))}
              onAddNew={(name) => onAddSpreader(name)}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              label="Role (optional)"
              value={spreaderEvent.role}
              onChange={(e) => setSpreaderEvent({ ...spreaderEvent, role: e.target.value })}
              helperText="E.g., Originator, Reporter, etc."
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              label="Quote (optional)"
              value={spreaderEvent.quote}
              onChange={(e) => setSpreaderEvent({ ...spreaderEvent, quote: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              label="Source URL (optional)"
              value={spreaderEvent.sourceUrl}
              onChange={(e) => setSpreaderEvent({ ...spreaderEvent, sourceUrl: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <DatePicker
              label="Date (optional)"
              value={spreaderEvent.date ? dayjs(spreaderEvent.date) : null}
              format={DAYJS_DATE_FORMAT}
              onAccept={(date) =>
                setSpreaderEvent((prev) => ({ ...prev, date: date?.format('YYYY-MM-DD') ?? null }))
              }
              onChange={(date) =>
                setSpreaderEvent((prev) => ({ ...prev, date: date?.format('YYYY-MM-DD') ?? null }))
              }
              slotProps={{
                actionBar: { actions: ['cancel', 'clear'] },
                field: { shouldRespectLeadingZeros: true },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <LoadingButton variant="contained" type="submit" loading={isLoading}>
            Submit
          </LoadingButton>
        </Grid>
        {error && (
          <Grid item>
            <Alert severity="error">{getErrorMessage(error)}</Alert>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
