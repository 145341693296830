import { Alert, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { InternalLink } from 'components/shared/InternalLink';
import { IMAGE_MISSING_URL } from 'config';
import { PropsWithChildren } from 'react';
import { getStoryRoute } from 'routes';

interface IProps {
  id: string;
  slug: string;
  isApproved: boolean;
  summary: string;
  imageUrl: string | null;
}

export function StoryCard(props: PropsWithChildren<IProps>) {
  const { id, slug, isApproved, summary, imageUrl, children } = props;

  return (
    <Card>
      {!isApproved && <Alert severity="warning">Awaiting approval</Alert>}
      <CardMedia sx={{ height: 140 }} image={imageUrl ?? IMAGE_MISSING_URL} />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          "{summary}"
        </Typography>
        {children}
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <InternalLink to={getStoryRoute(id, slug)}>View story</InternalLink>
      </CardActions>
    </Card>
  );
}
