import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useAuthProvider } from 'providers/AuthProvider';
import { useState } from 'react';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';
import { useApproveStoryMutation, useGetStoryQuery } from 'store/stories/storiesApi';
import { getErrorMessage } from 'utils';

export function ApproveStoryWidget() {
  const { storyId } = useStoryRouteParams();
  const { user } = useAuthProvider();

  const canPublish = user && user.isAdmin;

  const queryState = useGetStoryQuery({ storyId }, { skip: !canPublish });

  const [approveStory, approveStoryState] = useApproveStoryMutation();

  const [open, setOpen] = useState(false);

  if (!canPublish) {
    return null;
  }

  if (queryState.data === undefined) {
    return null;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = () => {
    approveStory({ storyId });
  };

  const { summary } = queryState.data;

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>
        Approve
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Approve this story?</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText tabIndex={-1}>"{summary}"</DialogContentText>
            <Alert severity="warning">Once approved, you will no longer be able to edit it.</Alert>
            {approveStoryState.error && (
              <Alert severity="error">{getErrorMessage(approveStoryState.error)}</Alert>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={handleApprove}
            loading={approveStoryState.isLoading}
          >
            Approve
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
