import { useParams } from 'react-router-dom';

type SpreaderRouteParams = { spreaderId: string; slug: string };

export function useSpreaderRouteParams(): SpreaderRouteParams {
  const { spreaderId, slug } = useParams<SpreaderRouteParams>();

  if (spreaderId === undefined) {
    throw new Error('Cannot use spreader route params outside of spreader route path.');
  }

  // If slug is undefined, use default and allow the page to handle it
  return { spreaderId, slug: slug ?? '_' };
}
