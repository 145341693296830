import { Card, CardContent, Grid } from '@mui/material';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { SpreaderCard } from 'components/shared/SpreaderCard';
import { SpreaderEventBox } from 'components/shared/SpreaderEventBox';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';
import { useGetStoryQuery } from 'store/stories/storiesApi';
import { sortByDate } from 'utils';
import { AddSpreaderEventWidget } from './AddSpreaderEventWidget';
import { HeaderWidget } from './HeaderWidget';

export function StorySpreadersPage() {
  const { storyId } = useStoryRouteParams();

  const queryState = useGetStoryQuery({ storyId });

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  return (
    <>
      <HeaderWidget />

      <Grid container spacing={2}>
        {queryState.data.spreaders
          .slice()
          .sort(sortByDate)
          .map(({ id, spreaderId, spreaderSlug, name, imageUrl, role, quote, sourceUrl, date }) => (
            <Grid item key={id} xs={12} sm={4}>
              <SpreaderCard id={spreaderId} slug={spreaderSlug} name={name} imageUrl={imageUrl}>
                <SpreaderEventBox role={role} quote={quote} sourceUrl={sourceUrl} date={date} />
              </SpreaderCard>
            </Grid>
          ))}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <AddSpreaderEventWidget />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
