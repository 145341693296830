import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { Footer } from './Footer';
import { Title } from './Title';

const defaultTheme = createTheme();

export function AppLayout(props: PropsWithChildren<{}>) {
  const { children } = props;

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: 'calc(100vh - 140px)' }}>
        <Container maxWidth="lg">
          <Title />
          <main>{children}</main>
        </Container>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}
