import { jwtDecode } from 'jwt-decode';
import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

const ID_TOKEN_STORAGE_KEY = 'WhoGotHoaxed:IdToken';

export function getIdToken(): string | null {
  return localStorage.getItem(ID_TOKEN_STORAGE_KEY);
}

interface IUser {
  id: number;
  isAdmin: boolean;
}

function getUser(idToken: string | null): IUser | null {
  if (!idToken) {
    return null;
  }

  const { unique_name, role } = jwtDecode<{
    unique_name: string;
    role: string | Array<string> | undefined;
  }>(idToken);

  const roles = Array.isArray(role) ? role : role ? [role] : [];

  return {
    id: Number(unique_name),
    isAdmin: roles.includes('Admin'),
  };
}

interface IContext {
  user: IUser | null;
  setIdToken: (idToken: string) => void;
  clearIdToken: () => void;
}

const AuthProviderContext = createContext<IContext | undefined>(undefined);

export function AuthProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const idToken = getIdToken();
  const [user, setUser] = useState(getUser(idToken));

  const setIdToken = useCallback((newIdToken: string) => {
    localStorage.setItem(ID_TOKEN_STORAGE_KEY, newIdToken);
    setUser(getUser(newIdToken));
  }, []);

  const clearIdToken = useCallback(() => {
    localStorage.removeItem(ID_TOKEN_STORAGE_KEY);
    setUser(null);
  }, []);

  return (
    <AuthProviderContext.Provider value={{ user, setIdToken, clearIdToken }}>
      {children}
    </AuthProviderContext.Provider>
  );
}

export function useAuthProvider(): IContext {
  const authContext = useContext(AuthProviderContext);

  if (!authContext) {
    throw new Error('useAuthProvider must be used inside an AuthProvider.');
  }

  return authContext;
}

export const setDefaultHeaders = async (headers: Headers) => {
  const idToken = getIdToken();
  if (idToken) {
    headers.set('Authorization', `Bearer ${idToken}`);
  }
  headers.set('Content-type', 'application/json');

  return headers;
};
