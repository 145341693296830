import { Box, Typography } from '@mui/material';
import { ExternalLink } from 'components/shared/ExternalLink';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useUserInfoQuery } from 'store/users/usersApi';

export function ClaimAccountPage() {
  const queryState = useUserInfoQuery();

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const { username } = queryState.data;

  const subject = `Claim "${username}"`;
  const body = `Hi, I would like to claim the account "${username}".`;
  const emailLink = `mailto:hello@whogothoaxed.com?subject=${subject}&body=${body}`;

  return (
    <Box py={2}>
      <Typography component="h3" variant="h6" gutterBottom>
        Claim this account: "{username}"
      </Typography>
      <Typography>
        Simply email us at <ExternalLink href={emailLink}>hello@whogothoaxed.com</ExternalLink> and
        we will take care of the rest.
      </Typography>
    </Box>
  );
}
