import { LoadingButton } from '@mui/lab';
import { Alert, Box, FormControl, FormLabel, Grid, Paper, TextField } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { HtmlEditor } from 'components/shared/HtmlEditor';
import { FormEvent, useState } from 'react';
import { ICreateOrUpdateSpreaderPayload } from 'store/spreaders/types';
import { getErrorMessage } from 'utils';

interface IProps {
  spreader?: Partial<ICreateOrUpdateSpreaderPayload>;
  onSubmit: (spreader: ICreateOrUpdateSpreaderPayload) => void;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export function SpreaderForm(props: IProps) {
  const { spreader: initialSpreader, onSubmit, isLoading, error } = props;

  const [spreader, setSpreader] = useState<ICreateOrUpdateSpreaderPayload>({
    name: initialSpreader?.name ?? '',
    imageUrl: initialSpreader?.imageUrl ?? '',
    bio: initialSpreader?.bio ?? '',
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(spreader);
  };

  return (
    <Paper>
      <Box component="form" p={2} onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                label="Name"
                value={spreader.name}
                onChange={(e) => setSpreader({ ...spreader, name: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                label="Image URL (optional)"
                value={spreader.imageUrl ?? ''}
                onChange={(e) => setSpreader({ ...spreader, imageUrl: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <FormLabel>Bio (optional)</FormLabel>
              <HtmlEditor
                value={spreader.bio ?? ''}
                onChange={(bio) => setSpreader({ ...spreader, bio })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <LoadingButton variant="contained" type="submit" loading={isLoading}>
              Submit
            </LoadingButton>
          </Grid>
          {error && (
            <Grid item>
              <Alert severity="error">{getErrorMessage(error)}</Alert>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
}
