import { LoadingButton } from '@mui/lab';
import { Alert, Box, FormControl, Grid, Paper, TextField } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { HtmlEditor } from 'components/shared/HtmlEditor';
import { FormEvent, useState } from 'react';
import { ICreateOrUpdateAnalysisPayload, Verdict } from 'store/stories/types';
import { getErrorMessage } from 'utils';
import { VerdictControl } from './VerdictControl';

interface IProps {
  analysis?: ICreateOrUpdateAnalysisPayload;
  onSubmit: (analysis: ICreateOrUpdateAnalysisPayload) => void;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export function AnalysisForm(props: IProps) {
  const { analysis: initialAnalysis, onSubmit, isLoading, error } = props;

  const [analysis, setAnalysis] = useState<ICreateOrUpdateAnalysisPayload>(
    initialAnalysis ?? { verdict: 'False' as Verdict, title: '', text: '<p></p>' }
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(analysis);
  };

  return (
    <Paper>
      <Box component="form" p={2} onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl>
              <VerdictControl
                value={analysis.verdict}
                onChange={(value) => setAnalysis({ ...analysis, verdict: value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                label="Title"
                value={analysis.title}
                onChange={(e) => setAnalysis({ ...analysis, title: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <HtmlEditor
                placeholder="Text"
                value={analysis.text}
                onChange={(value) => setAnalysis({ ...analysis, text: value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <LoadingButton variant="contained" type="submit" loading={isLoading}>
              Submit
            </LoadingButton>
          </Grid>
          {error && (
            <Grid item>
              <Alert severity="error">{getErrorMessage(error)}</Alert>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
}
