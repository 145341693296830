import { Grid, Typography } from '@mui/material';
import { SignInDialog } from 'components/Account/SignInDialog';
import { GetInTouchBox } from 'components/shared/GetInTouchBox';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getAnalysisRoute } from 'routes';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';
import { useCreateAnalysisMutation } from 'store/stories/storiesApi';
import { ICreateOrUpdateAnalysisPayload } from 'store/stories/types';
import { AnalysisForm } from './AnalysisForm';
import { HeaderWidget } from './HeaderWidget';

export function AddAnalysisPage() {
  const { storyId, slug } = useStoryRouteParams();
  const [createAnalysis, createAnalysisState] = useCreateAnalysisMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (createAnalysisState.isSuccess) {
      const { analysisId } = createAnalysisState.data;
      navigate(getAnalysisRoute(storyId, slug, analysisId));
    }
  }, [createAnalysisState, navigate, storyId, slug]);

  const handleSubmit = (analysis: ICreateOrUpdateAnalysisPayload) => {
    createAnalysis({ storyId, payload: analysis });
  };

  return (
    <>
      <SignInDialog />

      <HeaderWidget />

      <Grid container spacing={5} sx={{ mt: 1 }}>
        <Grid item xs={12} md={8}>
          <Typography component="h3" variant="h6" gutterBottom>
            Add your own analysis on this story:
          </Typography>

          <AnalysisForm
            onSubmit={handleSubmit}
            isLoading={createAnalysisState.isLoading}
            error={createAnalysisState.error}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GetInTouchBox />
        </Grid>
      </Grid>
    </>
  );
}
