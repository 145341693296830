import { useParams } from 'react-router-dom';

type AnalysisRouteParams = { storyId: string; slug: string; analysisId: string };

export function useAnalysisRouteParams(): AnalysisRouteParams {
  const { storyId, slug, analysisId } = useParams<AnalysisRouteParams>();

  if (storyId === undefined || slug === undefined || analysisId === undefined) {
    throw new Error('Cannot use analysis route params outside of analysis route path.');
  }

  return { storyId, slug, analysisId };
}
