import { Box, Grid, Typography } from '@mui/material';
import { SignInDialog } from 'components/Account/SignInDialog';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getStoryRoute } from 'routes';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';
import { useGetStoryQuery, useUpdateStoryMutation } from 'store/stories/storiesApi';
import { ICreateOrUpdateStoryPayload } from 'store/stories/types';
import { StoryForm } from './StoryForm';

export function EditStoryPage() {
  const { storyId, slug } = useStoryRouteParams();
  const queryState = useGetStoryQuery({ storyId });
  const [updateStory, updateStoryState] = useUpdateStoryMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (updateStoryState.isSuccess) {
      navigate(getStoryRoute(storyId, slug));
    }
  }, [updateStoryState, navigate, storyId, slug]);

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const handleSubmit = (payload: ICreateOrUpdateStoryPayload) => {
    updateStory({ storyId, payload });
  };

  const story = queryState.data;

  return (
    <Box py={2}>
      <SignInDialog />

      <Typography component="h3" variant="h6" gutterBottom>
        Edit story
      </Typography>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <StoryForm
            story={story}
            onSubmit={handleSubmit}
            isLoading={updateStoryState.isLoading}
            error={updateStoryState.error}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
