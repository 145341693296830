import { Box, Grid, Typography } from '@mui/material';
import { SignInDialog } from 'components/Account/SignInDialog';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getAddAnalysisRoute } from 'routes';
import { useCreateStoryMutation } from 'store/stories/storiesApi';
import { ICreateOrUpdateStoryPayload } from 'store/stories/types';
import { StoryForm } from './StoryForm';

export function AddStoryPage() {
  const [createStory, createStoryState] = useCreateStoryMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (createStoryState.isSuccess) {
      const { storyId, slug } = createStoryState.data;
      navigate(getAddAnalysisRoute(storyId, slug));
    }
  }, [createStoryState, navigate]);

  const handleSubmit = (payload: ICreateOrUpdateStoryPayload) => {
    createStory(payload);
  };

  return (
    <Box py={2}>
      <SignInDialog />

      <Typography component="h3" variant="h6" gutterBottom>
        Submit a new story
      </Typography>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <StoryForm
            onSubmit={handleSubmit}
            isLoading={createStoryState.isLoading}
            error={createStoryState.error}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
