import { Box, FormLabel, Stack } from '@mui/material';
import { Verdict } from 'store/stories/types';
import { VerdictDisplay } from './VerdictDisplay';

interface IProps {
  value: Verdict;
  onChange: (value: Verdict) => void;
}

export function VerdictControl(props: IProps) {
  const { value, onChange } = props;

  const verdicts: Array<Verdict> = ['True', 'Unclear', 'False'];

  return (
    <Stack>
      <FormLabel>Verdict: this story is...</FormLabel>

      <Box sx={{ border: 1, borderColor: '#c4c4c4', borderRadius: '4px', padding: 1 }}>
        <Stack direction="row" spacing={1}>
          {verdicts.map((verdict) => (
            <VerdictDisplay
              key={verdict}
              value={verdict}
              isActive={value === verdict}
              onClick={() => onChange(verdict)}
            />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}
