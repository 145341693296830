import { LoadingButton } from '@mui/lab';
import { Alert, Box, FormControl, Grid, Paper, TextField, Typography } from '@mui/material';
import { InternalLink } from 'components/shared/InternalLink';
import { PasswordField } from 'components/shared/PasswordField';
import { useAuthProvider } from 'providers/AuthProvider';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSignUpRoute } from 'routes';
import { useResetUserSpecificData } from 'store/useResetUserSpecificData';
import { ISignInPayload } from 'store/users/types';
import { useSignInMutation } from 'store/users/usersApi';
import { getErrorMessage } from 'utils';
import { OrSignInAnonymouslyControl } from './OrSignInAnonymouslyControl';

export function SignInPage() {
  const [searchParams] = useSearchParams();
  const verified = searchParams.get('verified');
  const email = searchParams.get('email') ?? '';

  const [signIn, signInState] = useSignInMutation();
  const { user, setIdToken } = useAuthProvider();
  const { resetUserSpecificData } = useResetUserSpecificData();
  const navigate = useNavigate();

  const [payload, setPayload] = useState<ISignInPayload>({ email, password: '' });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    signIn(payload);
  };

  useEffect(() => {
    if (signInState.isSuccess) {
      setIdToken(signInState.data.token);
      resetUserSpecificData();
    }
  }, [setIdToken, signInState, resetUserSpecificData]);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <Box py={2}>
      {verified && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Email address successfully verified, you can now sign in below.
        </Alert>
      )}

      <Typography component="h3" variant="h6" gutterBottom>
        Sign in
      </Typography>

      {!verified && (
        <Typography mb={2}>
          Don't have an account? <InternalLink to={getSignUpRoute()}>Sign up here</InternalLink>.
        </Typography>
      )}

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box component="form" p={2} onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormControl fullWidth>
                    <TextField
                      label="Email address"
                      type="email"
                      value={payload.email}
                      onChange={(e) => setPayload({ ...payload, email: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <PasswordField
                      label="Password"
                      value={payload.password}
                      onChange={(e) => setPayload({ ...payload, password: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <LoadingButton variant="contained" type="submit" loading={signInState.isLoading}>
                    Sign in
                  </LoadingButton>
                </Grid>
                {signInState.isError && signInState.error && (
                  <Grid item>
                    <Alert severity="error">{getErrorMessage(signInState.error)}</Alert>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <OrSignInAnonymouslyControl />
    </Box>
  );
}
