import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'config';
import { setDefaultHeaders } from 'providers/AuthProvider';
import {
  ICreateOrUpdateSpreaderPayload,
  ICreateSpreaderResult,
  ISpreaderDetail,
  ISpreaderList,
} from './types';

export const spreadersApi = createApi({
  reducerPath: 'spreadersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/spreaders`,
    prepareHeaders: setDefaultHeaders,
  }),
  tagTypes: ['Spreader'],
  endpoints: (builder) => ({
    createSpreader: builder.mutation<ICreateSpreaderResult, ICreateOrUpdateSpreaderPayload>({
      query: (payload) => ({ url: '', method: 'POST', body: payload }),
      invalidatesTags: [{ type: 'Spreader', id: 'LIST' }],
    }),
    getAllSpreaders: builder.query<ISpreaderList, void>({
      query: () => ({ url: '', method: 'GET' }),
      providesTags: [{ type: 'Spreader', id: 'LIST' }],
    }),
    getSpreader: builder.query<ISpreaderDetail, { spreaderId: string }>({
      query: ({ spreaderId }) => ({ url: spreaderId, method: 'GET' }),
      providesTags: (result, error, { spreaderId }) => [{ type: 'Spreader', id: spreaderId }],
    }),
    updateSpreader: builder.mutation<
      void,
      { spreaderId: string; payload: ICreateOrUpdateSpreaderPayload }
    >({
      query: ({ spreaderId, payload }) => ({ url: spreaderId, method: 'PUT', body: payload }),
      invalidatesTags: (result, error, { spreaderId }) =>
        error
          ? []
          : [
              { type: 'Spreader', id: 'LIST' },
              { type: 'Spreader', id: spreaderId },
            ],
    }),
  }),
});

export const {
  useCreateSpreaderMutation,
  useGetAllSpreadersQuery,
  useGetSpreaderQuery,
  useUpdateSpreaderMutation,
} = spreadersApi;
