import { Box, Grid, Typography } from '@mui/material';
import { SignInDialog } from 'components/Account/SignInDialog';
import { ADD_SPREADER_EVENT_WIDGET_STATE } from 'components/Stories/AddSpreaderEventWidget';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getSpreaderRoute } from 'routes';
import { useCreateSpreaderMutation } from 'store/spreaders/spreadersApi';
import { ICreateOrUpdateSpreaderPayload } from 'store/spreaders/types';
import { SpreaderForm } from './SpreaderForm';

export type SPREADER_PAGE_STATE = { returnPath: string; name: string };

export function AddSpreaderPage() {
  const [createSpreader, createSpreaderState] = useCreateSpreaderMutation();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { returnPath, name } = (state as SPREADER_PAGE_STATE) ?? {};

  useEffect(() => {
    if (createSpreaderState.isSuccess) {
      const { spreaderId, slug } = createSpreaderState.data;

      if (returnPath) {
        navigate(returnPath, {
          state: { spreaderId } as ADD_SPREADER_EVENT_WIDGET_STATE,
        });
      } else {
        navigate(getSpreaderRoute(spreaderId, slug));
      }
    }
  }, [createSpreaderState, navigate, returnPath]);

  const handleSubmit = (payload: ICreateOrUpdateSpreaderPayload) => {
    createSpreader(payload);
  };

  return (
    <Box py={2}>
      <SignInDialog />

      <Typography component="h3" variant="h6" gutterBottom>
        Submit a new spreader
      </Typography>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <SpreaderForm
            spreader={{ name }}
            onSubmit={handleSubmit}
            isLoading={createSpreaderState.isLoading}
            error={createSpreaderState.error}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
