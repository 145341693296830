import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'config';
import { setDefaultHeaders } from 'providers/AuthProvider';
import {
  ICreateAnalysisResult,
  ICreateOrUpdateAnalysisPayload,
  ICreateOrUpdateSpreaderEventPayload,
  ICreateOrUpdateStoryPayload,
  ICreateSpreaderEventResult,
  ICreateStoryResult,
  IStoryDetail,
  IStoryList,
} from './types';

export const storiesApi = createApi({
  reducerPath: 'storiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/stories`,
    prepareHeaders: setDefaultHeaders,
  }),
  tagTypes: ['Story'],
  endpoints: (builder) => ({
    approveStory: builder.mutation<void, { storyId: string }>({
      query: ({ storyId }) => ({
        url: `${storyId}/approve`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { storyId }) =>
        error
          ? []
          : [
              { type: 'Story', id: 'LIST' },
              { type: 'Story', id: storyId },
            ],
    }),
    createAnalysis: builder.mutation<
      ICreateAnalysisResult,
      { storyId: string; payload: ICreateOrUpdateAnalysisPayload }
    >({
      query: ({ storyId, payload }) => ({
        url: `${storyId}/analyses`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { storyId }) =>
        error ? [] : [{ type: 'Story', id: storyId }],
    }),
    createSpreaderEvent: builder.mutation<
      ICreateSpreaderEventResult,
      { storyId: string; payload: ICreateOrUpdateSpreaderEventPayload }
    >({
      query: ({ storyId, payload }) => ({
        url: `${storyId}/spreader-events`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { storyId }) =>
        error ? [] : [{ type: 'Story', id: storyId }],
    }),
    createStory: builder.mutation<ICreateStoryResult, ICreateOrUpdateStoryPayload>({
      query: (payload) => ({ url: '', method: 'POST', body: payload }),
      invalidatesTags: [{ type: 'Story', id: 'LIST' }],
    }),
    createOrDeleteUpvote: builder.mutation<
      void,
      { storyId: string; analysisId: string; create: boolean }
    >({
      query: ({ storyId, analysisId, create }) => ({
        url: `${storyId}/analyses/${analysisId}/upvotes`,
        method: create ? 'POST' : 'DELETE',
      }),
      invalidatesTags: (result, error, { storyId }) =>
        error ? [] : [{ type: 'Story', id: storyId }],
    }),
    getAllStories: builder.query<IStoryList, void>({
      query: () => ({ url: '', method: 'GET' }),
      providesTags: [{ type: 'Story', id: 'LIST' }],
    }),
    getStory: builder.query<IStoryDetail, { storyId: string }>({
      query: ({ storyId }) => ({ url: storyId, method: 'GET' }),
      providesTags: (result, error, { storyId }) => [{ type: 'Story', id: storyId }],
    }),
    publishAnalysis: builder.mutation<void, { storyId: string; analysisId: string }>({
      query: ({ storyId, analysisId }) => ({
        url: `${storyId}/analyses/${analysisId}/publish`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { storyId }) =>
        error
          ? []
          : [
              { type: 'Story', id: 'LIST' },
              { type: 'Story', id: storyId },
            ],
    }),
    updateAnalysis: builder.mutation<
      void,
      { storyId: string; analysisId: string; payload: ICreateOrUpdateAnalysisPayload }
    >({
      query: ({ storyId, analysisId, payload }) => ({
        url: `${storyId}/analyses/${analysisId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { storyId }) =>
        error ? [] : [{ type: 'Story', id: storyId }],
    }),
    updateStory: builder.mutation<void, { storyId: string; payload: ICreateOrUpdateStoryPayload }>({
      query: ({ storyId, payload }) => ({ url: storyId, method: 'PUT', body: payload }),
      invalidatesTags: (result, error, { storyId }) =>
        error
          ? []
          : [
              { type: 'Story', id: 'LIST' },
              { type: 'Story', id: storyId },
            ],
    }),
  }),
});

export const {
  useApproveStoryMutation,
  useCreateAnalysisMutation,
  useCreateSpreaderEventMutation,
  useCreateStoryMutation,
  useCreateOrDeleteUpvoteMutation,
  useGetAllStoriesQuery,
  useGetStoryQuery,
  usePublishAnalysisMutation,
  useUpdateAnalysisMutation,
  useUpdateStoryMutation,
} = storiesApi;
