import { useParams } from 'react-router-dom';

type StoryRouteParams = { storyId: string; slug: string };

export function useStoryRouteParams(): StoryRouteParams {
  const { storyId, slug } = useParams<StoryRouteParams>();

  if (storyId === undefined) {
    throw new Error('Cannot use story route params outside of story route path.');
  }

  // If slug is undefined, use default and allow the page to handle it
  return { storyId, slug: slug ?? '_' };
}
