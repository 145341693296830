import { Alert, Snackbar, Stack } from '@mui/material';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useEffect, useState } from 'react';
import { useAnalysisRouteParams } from 'routes/useAnalysisRouteParams';
import { useCreateOrDeleteUpvoteMutation, useGetStoryQuery } from 'store/stories/storiesApi';
import { getErrorMessage } from 'utils';
import { UpvotesControl } from './UpvotesControl';

export function UpvotesWidget() {
  const { storyId, analysisId } = useAnalysisRouteParams();

  const queryState = useGetStoryQuery({ storyId });

  const [createOrDelete, createOrDeleteState] = useCreateOrDeleteUpvoteMutation();

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (createOrDeleteState.error) {
      setShowError(true);
    }
  }, [createOrDeleteState]);

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const analysis = queryState.data.analyses.find((analysis) => analysis.id === analysisId);

  if (!analysis) {
    return null;
  }

  const handleClick = () => {
    createOrDelete({ storyId, analysisId, create: !analysis.upvotedByCurrentUser });
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <Stack spacing={1}>
      <UpvotesControl
        upvotesCount={analysis.upvotesCount}
        isActive={analysis.upvotedByCurrentUser}
        loading={createOrDeleteState.isLoading}
        onClick={handleClick}
      />

      {createOrDeleteState.error && (
        <Snackbar open={showError} autoHideDuration={6000} onClose={handleCloseError}>
          <Alert severity="error">{getErrorMessage(createOrDeleteState.error)}</Alert>
        </Snackbar>
      )}
    </Stack>
  );
}
