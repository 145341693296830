import {
  Autocomplete,
  Avatar,
  CircularProgress,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { useEffect, useState } from 'react';

type Option = {
  id: string;
  name: string;
  imageUrl?: string | null;
};

type AutosuggestOption = Omit<Option, 'id'> & {
  inputValue?: string;
  id?: string;
};

function mapOptions(options: Array<Option> | undefined): Array<AutosuggestOption> {
  return options?.slice().sort((a, b) => a.name.localeCompare(b.name)) ?? [];
}

/**
 * Case and whitespace insensitive string equality
 */
function isMatch(a: string, b: string): boolean {
  return a.localeCompare(b.trim(), undefined, { sensitivity: 'base' }) === 0;
}

const filter = createFilterOptions<AutosuggestOption>({ trim: true });

interface IProps {
  label: string;
  value: string;
  options: Array<Option> | undefined;
  onChange: (id: string) => void;
  onAddNew: (name: string) => void;
}

export function Autosuggest(props: IProps) {
  const { label, value, options, onChange, onAddNew } = props;

  const [autosuggestOptions, setAutosuggestOptions] = useState<Array<AutosuggestOption>>([]);

  useEffect(() => {
    setAutosuggestOptions(mapOptions(options));
  }, [setAutosuggestOptions, options]);

  const isLoading = autosuggestOptions === undefined;

  return (
    <Autocomplete
      value={options?.find((o) => o.id === value) ?? null}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      options={autosuggestOptions}
      getOptionKey={(option) => (typeof option === 'string' ? option : option.id ?? '')}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, { name, imageUrl }) => (
        <li {...props}>
          {imageUrl && <Avatar alt={name} src={imageUrl} sx={{ width: 24, height: 24, mr: 1 }} />}
          {name}
        </li>
      )}
      filterOptions={(o, params) => {
        const filtered = filter(o, params);

        const { inputValue } = params;
        if (!isLoading && inputValue !== '') {
          const isExisting = o.some((option) => isMatch(option.name, inputValue));
          if (!isExisting) {
            // Suggest the creation of a new value
            filtered.push({ inputValue, name: `Add "${inputValue}"` });
          }
        }

        return filtered;
      }}
      onChange={(event, newValue) => {
        if (newValue !== null && typeof newValue !== 'string') {
          if (newValue.id) {
            onChange(newValue.id);
          } else {
            onAddNew(newValue.inputValue ?? '');
          }
        }
      }}
    />
  );
}
