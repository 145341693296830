import { Box, Grid, Typography } from '@mui/material';
import { SignInDialog } from 'components/Account/SignInDialog';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getSpreaderRoute } from 'routes';
import { useSpreaderRouteParams } from 'routes/useSpreaderRouteParams';
import { useGetSpreaderQuery, useUpdateSpreaderMutation } from 'store/spreaders/spreadersApi';
import { ICreateOrUpdateSpreaderPayload } from 'store/spreaders/types';
import { SpreaderForm } from './SpreaderForm';

export function EditSpreaderPage() {
  const { spreaderId, slug } = useSpreaderRouteParams();
  const queryState = useGetSpreaderQuery({ spreaderId });
  const [updateSpreader, updateSpreaderState] = useUpdateSpreaderMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (updateSpreaderState.isSuccess) {
      navigate(getSpreaderRoute(spreaderId, slug));
    }
  }, [updateSpreaderState, navigate, spreaderId, slug]);

  if (queryState.data === undefined) {
    return <QueryStateDisplay queryState={queryState} />;
  }

  const handleSubmit = (payload: ICreateOrUpdateSpreaderPayload) => {
    updateSpreader({ spreaderId, payload });
  };

  const spreader = queryState.data;

  return (
    <Box py={2}>
      <SignInDialog />

      <Typography component="h3" variant="h6" gutterBottom>
        Edit spreader
      </Typography>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <SpreaderForm
            spreader={spreader}
            onSubmit={handleSubmit}
            isLoading={updateSpreaderState.isLoading}
            error={updateSpreaderState.error}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
