import dayjs from 'dayjs';
import { IAnalysisDetail } from 'store/stories/types';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const DAYJS_DATE_FORMAT = 'MMMM D YYYY';

export function formatDate(date: string | null): string {
  return (date ? dayjs(date) : dayjs()).format(DAYJS_DATE_FORMAT);
}

export const getErrorMessage = (
  error: FetchBaseQueryError | SerializedError | undefined
): string | null => {
  if (!error) {
    return null;
  }

  const { status } = error as FetchBaseQueryError;

  switch (status) {
    case 'FETCH_ERROR': {
      return 'Unable to connect to the internet. Please check your connection and try again.';
    }
    case 'PARSING_ERROR': {
      return 'Analysis was not valid json.';
    }
    case 'TIMEOUT_ERROR': {
      return 'API call has timed out. Please try again later.';
    }
    case 'CUSTOM_ERROR': {
      return 'A custom error occurred.';
    }
    default: {
      if (status >= 400 && status < 500) {
        const clientError = (error as FetchBaseQueryError).data as { message?: string } | undefined;
        if (clientError?.message) {
          return clientError.message;
        }
        switch (status) {
          case 401: {
            return 'Unauthorized.';
          }
          case 404: {
            return 'Not found.';
          }
          default: {
            return 'An error occurred.';
          }
        }
      }
      console.error(error);
      return 'An unexpected error has occurred. If it persists, please contact support.';
    }
  }
};

export function getInitials(name: string): string {
  return name
    .split(' ')
    .map((w) => w[0])
    .join('')
    .toLocaleUpperCase();
}

export function sortByDate(a: { date: string | null }, b: { date: string | null }): number {
  if (a.date && b.date) {
    return a.date.localeCompare(b.date);
  }

  return a.date ? -1 : 1;
}

export function sortByHasImageUrl(
  a: { imageUrl: string | null },
  b: { imageUrl: string | null }
): number {
  return a.imageUrl ? (b.imageUrl ? 0 : -1) : 1;
}

export function sortAnalyses(
  analyses: Array<IAnalysisDetail>
): Array<{ analysis: IAnalysisDetail; isTopRated: boolean }> {
  const topRatedAnalyses = new Array<IAnalysisDetail>();
  const moreAnalyses = new Array<IAnalysisDetail>();

  const analysesByUpvotes = analyses.slice().sort((a, b) => b.upvotesCount - a.upvotesCount);

  analysesByUpvotes.forEach((analysis) => {
    if (!topRatedAnalyses.some((x) => x.verdict === analysis.verdict)) {
      topRatedAnalyses.push(analysis);
    } else {
      moreAnalyses.push(analysis);
    }
  });

  return topRatedAnalyses
    .map((analysis) => ({ analysis, isTopRated: true }))
    .concat(moreAnalyses.map((analysis) => ({ analysis, isTopRated: false })));
}
