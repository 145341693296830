import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ExternalLink } from 'components/shared/ExternalLink';

export function Footer() {
  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', pt: 6, pb: 2 }}>
      <Container maxWidth="lg">
        <Typography variant="h6" align="center" gutterBottom>
          Got feedback?
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
          Get in touch: <ExternalLink href="https://x.com/whogothoaxed">@whogothoaxed</ExternalLink>
          , <ExternalLink href="mailto:hello@whogothoaxed.com">hello@whogothoaxed.com</ExternalLink>
        </Typography>
      </Container>
    </Box>
  );
}
