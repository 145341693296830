import { Alert, Box, Stack, Typography } from '@mui/material';
import { InternalLinkButton } from 'components/shared/InternalLinkButton';
import { ShareButton } from 'components/shared/ShareButton';
import { getAnalysisRoute, getEditAnalysisRoute } from 'routes';
import { IAnalysisDetail } from 'store/stories/types';
import { Byline } from './Byline';
import { PublishAnalysisWidget } from './PublishAnalysisWidget';
import { UpvotesWidget } from './UpvotesWidget';
import { VerdictDisplay } from './VerdictDisplay';

interface IProps {
  storyId: string;
  slug: string;
  analysis: IAnalysisDetail;
  currentUserId: number | null;
}

export function AnalysisDisplay(props: IProps) {
  const { storyId, slug, analysis, currentUserId } = props;
  const {
    id: analysisId,
    createdByUserId,
    createdByUsername,
    verdict,
    title,
    text,
    publishedAt,
  } = analysis;

  const isPublished = publishedAt !== null;
  const userCanEdit = currentUserId === createdByUserId && !isPublished;

  return (
    <Box>
      {!isPublished && (
        <Alert severity="warning" action={<PublishAnalysisWidget />} sx={{ mb: 2 }}>
          This analysis is not yet published, so only you can see it.
        </Alert>
      )}

      <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>Verdict:</Typography>
          <VerdictDisplay value={verdict} isActive />
        </Stack>

        <UpvotesWidget />

        {isPublished && <ShareButton route={getAnalysisRoute(storyId, slug, analysisId)} />}
      </Stack>

      <Typography component="h3" variant="h4" gutterBottom sx={{ mb: 1 }}>
        {title}
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Byline publishedAt={publishedAt} createdByUsername={createdByUsername} />
      </Box>

      <Box sx={{ mb: 2 }}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Box>

      {userCanEdit && (
        <InternalLinkButton
          to={getEditAnalysisRoute(storyId, slug, analysisId)}
          size="small"
          variant="contained"
        >
          Edit
        </InternalLinkButton>
      )}
    </Box>
  );
}
