import { Avatar, Stack, Typography } from '@mui/material';

interface IProps {
  name: string;
  imageUrl: string | null;
  size?: number;
}

export function SpreaderAvatar(props: IProps) {
  const { name, imageUrl, size } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
      {imageUrl && <Avatar alt={name} src={imageUrl} sx={{ width: size, height: size }} />}
      <Typography variant="h6" gutterBottom>
        {name}
      </Typography>
    </Stack>
  );
}
