import { Box, Grid, Stack, Typography } from '@mui/material';
import { InternalLink } from 'components/shared/InternalLink';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { SpreaderCard } from 'components/shared/SpreaderCard';
import { StoryCard } from 'components/shared/StoryCard';
import { SPREADERS_PATH, STORIES_PATH } from 'routes';
import { useGetAllSpreadersQuery } from 'store/spreaders/spreadersApi';
import { useGetAllStoriesQuery } from 'store/stories/storiesApi';

export function HomePage() {
  const spreadersQueryState = useGetAllSpreadersQuery();
  const storiesQueryState = useGetAllStoriesQuery();

  return (
    <Box py={2}>
      <Stack direction="row" spacing={1} alignItems="baseline" mb={1}>
        <Typography component="h3" variant="h6">
          Top spreaders
        </Typography>
        <InternalLink to={SPREADERS_PATH}>See all</InternalLink>
      </Stack>

      {spreadersQueryState.data === undefined ? (
        <QueryStateDisplay queryState={spreadersQueryState} />
      ) : (
        <Grid container spacing={2} mb={2}>
          {spreadersQueryState.data.spreaders
            .slice()
            .sort((a, b) => b.storiesCount - a.storiesCount)
            .slice(0, 3)
            .map(({ id, slug, name, imageUrl, storiesCount }) => (
              <Grid item key={id} xs={12} sm={4}>
                <SpreaderCard id={id} slug={slug} name={name} imageUrl={imageUrl}>
                  <Typography>
                    has spread {storiesCount} {storiesCount === 1 ? 'story' : 'stories'}
                  </Typography>
                </SpreaderCard>
              </Grid>
            ))}
        </Grid>
      )}

      <Stack direction="row" spacing={1} alignItems="baseline" mb={1}>
        <Typography component="h3" variant="h6">
          Recently added stories
        </Typography>
        <InternalLink to={STORIES_PATH}>See all</InternalLink>
      </Stack>

      {storiesQueryState.data === undefined ? (
        <QueryStateDisplay queryState={storiesQueryState} />
      ) : (
        <Grid container spacing={2} mb={2}>
          {storiesQueryState.data.stories
            .slice()
            .filter((story) => story.approvedAt != null)
            .sort((a, b) => b.approvedAt!.localeCompare(a.approvedAt!))
            .slice(0, 3)
            .map(({ id, slug, summary, imageUrl }) => (
              <Grid item key={id} xs={12} sm={4}>
                <StoryCard id={id} slug={slug} isApproved summary={summary} imageUrl={imageUrl} />
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
  );
}
